import React, { useState } from 'react';
import { Button, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const ReportDownload: React.FC = () => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const { token, user } = useAuth();

    const handleDownload = async () => {
        if (!startDate || !endDate) {
            alert('Please select start and end dates');
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/download`, {
                params: {
                    startDate: startDate.toISOString().split('T')[0],
                    endDate: endDate.toISOString().split('T')[0],
                    userId: user?.id
                },
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` }
            });

            const contentDisposition = response.headers['content-disposition'];
            let filename = 'report.csv';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1].replace(/['"]/g, '');
                }
            } else {
                // Se Content-Disposition non è disponibile, crea un nome di file basato sulle date
                filename = `report_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.csv`;
            }

            console.log('Content-Disposition:', contentDisposition);
            console.log('Filename:', filename);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading report:', error);
            if (axios.isAxiosError(error)) {
                console.error('Axios error details:', error.response);
            }
            alert('Error downloading report. Please try again.');
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
            />
            <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
            />
            <Button onClick={handleDownload} variant="contained">
                Download Report
            </Button>
        </Box>
    );
};

export default ReportDownload;