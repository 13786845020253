import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Navbar from './components/Navbar';
import Auth from './components/Auth';
import Dashboard from './pages/Dashboard';
import Availability from './components/Availability';
import AdminDashboard from './components/AdminDashboard';

const ProtectedRoute: React.FC<{ children: React.ReactNode; requiredRole?: 'supervisor' }> = ({ children, requiredRole }) => {
    const { user, token, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!token) return <Navigate to="/login" />;
    if (requiredRole === 'supervisor' && !user?.isSupervisor) {
        return <Navigate to="/dashboard" />;
    }
    return <>{children}</>;
};

const App: React.FC = () => {
    return (
        <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Router>
                    <Navbar />
                    <Routes>
                        <Route path="/login" element={<Auth />} />
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                        <Route path="/availability" element={<ProtectedRoute><Availability /></ProtectedRoute>} />
                        <Route path="/admin" element={<ProtectedRoute requiredRole="supervisor"><AdminDashboard /></ProtectedRoute>} />
                    </Routes>
                </Router>
            </LocalizationProvider>
        </AuthProvider>
    );
};

export default App;