import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Grid, Paper, Checkbox, FormControlLabel,
    Button, Box, Divider, useTheme, useMediaQuery, Snackbar
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import { fetchUsers, fetchUserAvailabilities } from '../services/adminService';
import AvailabilityCalendarAdmin from './AvailabilityCalendarAdmin';
import { User, AvailabilityData } from '../types';
import OverviewCalendar from './OverviewCalendar';
import ReportDownloadView from './ReportDownloadView';

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const UserListPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: '400px',
    overflowY: 'auto',
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AdminDashboard: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [availabilities, setAvailabilities] = useState<{ [userId: number]: AvailabilityData[] }>({});
    const [viewMode, setViewMode] = useState<'individual' | 'overview' | 'download'>('individual');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const { token } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const loadUsers = async () => {
            if (token) {
                const fetchedUsers = await fetchUsers(token);
                setUsers(fetchedUsers);
            }
        };
        loadUsers();
    }, [token]);

    useEffect(() => {
        const loadAvailabilities = async () => {
            if (token && selectedUsers.length > 0) {
                const availabilitiesData: { [userId: number]: AvailabilityData[] } = {};
                for (const userId of selectedUsers) {
                    availabilitiesData[userId] = await fetchUserAvailabilities(token, userId);
                }
                setAvailabilities(availabilitiesData);
            }
        };
        loadAvailabilities();
    }, [selectedUsers, token]);

    const handleUserSelect = (userId: number) => {
        setSelectedUsers(prev =>
            prev.includes(userId)
                ? prev.filter(id => id !== userId)
                : [...prev, userId]
        );
    };

    const handleSelectAll = () => {
        setSelectedUsers(users.map(user => user.id));
    };

    const handleDeselectAll = () => {
        setSelectedUsers([]);
    };

    const toggleViewMode = () => {
        setViewMode(prev => prev === 'individual' ? 'overview' : 'individual');
    };

    const getSelectedUsersAvailabilities = () => {
        return selectedUsers.flatMap(userId => availabilities[userId] || []);
    };

    const handleDownloadClick = () => {
        setViewMode('download');
    };

    const handleBackFromDownload = () => {
        setViewMode('individual');
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <StyledContainer maxWidth="lg">
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mb: 3,
                background: 'linear-gradient(45deg, #00BFFF 30%, #1E90FF 90%)',
                borderRadius: 2,
                boxShadow: '0 3px 5px 2px rgba(0, 191, 255, .3)',
                color: 'white',
                padding: 2,
            }}>
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Admin Dashboard
                </Typography>
                <Typography variant="subtitle2" align="center">
                    Manage user availabilities efficiently
                </Typography>
            </Box>
            {viewMode === 'download' ? (
                <ReportDownloadView users={users} onBack={handleBackFromDownload} />
            ) : (
                <StyledPaper>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mb: 2 }}>
                        <StyledButton variant="contained" color="primary" onClick={handleSelectAll}>Select All</StyledButton>
                        <StyledButton variant="contained" color="secondary" onClick={handleDeselectAll}>Deselect All</StyledButton>
                        <StyledButton variant="contained" color="info" onClick={toggleViewMode}>
                            Switch to {viewMode === 'individual' ? 'Overview' : 'Individual'} View
                        </StyledButton>
                        <StyledButton variant="contained" color="success" onClick={handleDownloadClick}>
                            Download Report
                        </StyledButton>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <UserListPaper elevation={3}>
                                <Typography variant="h6" gutterBottom color="primary">Users</Typography>
                                {users.map(user => (
                                    <FormControlLabel
                                        key={user.id}
                                        control={
                                            <Checkbox
                                                checked={selectedUsers.includes(user.id)}
                                                onChange={() => handleUserSelect(user.id)}
                                                color="primary"
                                            />
                                        }
                                        label={user.username}
                                    />
                                ))}
                            </UserListPaper>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Paper elevation={3} sx={{ p: 2 }}>
                                {selectedUsers.length === 0 ? (
                                    <Typography variant="h6" align="center">Please select at least one user to view availabilities.</Typography>
                                ) : viewMode === 'individual' ? (
                                    selectedUsers.map(userId => (
                                        <Box key={userId} sx={{ mb: 4 }}>
                                            <AvailabilityCalendarAdmin
                                                availabilities={availabilities[userId] || []}
                                                username={users.find(u => u.id === userId)?.username}
                                                isOverview={false}
                                            />
                                        </Box>
                                    ))
                                ) : (
                                    <OverviewCalendar
                                        availabilities={getSelectedUsersAvailabilities()}
                                        users={users.filter(user => selectedUsers.includes(user.id))}
                                    />
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </StyledPaper>
            )}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </StyledContainer>
    );
};

export default AdminDashboard;