import React, { useState, useEffect, useCallback} from 'react';
import { Container, Typography, Grid, Paper, Button, FormControl, InputLabel, Select, MenuItem, TextField, Box, CircularProgress, List, ListItem, ListItemText, Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import LockIcon from '@mui/icons-material/Lock';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import {
    format,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    addMonths,
    isSunday,
    getDay,
    isToday,
    isSameDay,
    parseISO,
    isWithinInterval,
    subDays,
    addHours,
    subHours
} from 'date-fns';

const API_URL = process.env.REACT_APP_API_URL ||'https://presence.adsgrowrich.com:3000/api';
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

interface AvailabilityData {
    id: number;
    date: string;
    period: 'morning' | 'afternoon' | 'evening';
    status: 'available' | 'unavailable' | 'uncertain';
    note?: string;
    isAutoGenerated: boolean;
    checkInTime?: string | null;
    checkOutTime?: string | null;
}

const Availability: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [period, setPeriod] = useState<'morning' | 'afternoon' | 'evening'>('morning');
    const [status, setStatus] = useState<'available' | 'unavailable' | 'uncertain'>('available');
    const [note, setNote] = useState('');
    const [availabilities, setAvailabilities] = useState<AvailabilityData[]>([]);
    const [dailyAvailabilities, setDailyAvailabilities] = useState<AvailabilityData[]>([]);
    const [checkInStatus, setCheckInStatus] = useState<'loading' | 'none' | 'checkedIn' | 'checkedOut'>('loading');
    const [bulkStartDate, setBulkStartDate] = useState<Date | null>(null);
    const [bulkEndDate, setBulkEndDate] = useState<Date | null>(null);
    const [bulkNote, setBulkNote] = useState('');
    const { token, user } = useAuth();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isDailyAvailabilitiesLoading, setIsDailyAvailabilitiesLoading] = useState(false);

    const getCurrentPeriod = (): 'morning' | 'afternoon' | 'evening' => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        if (hours >= 6 && (hours < 13 || (hours === 13 && minutes < 30))) return 'morning';
        if ((hours === 13 && minutes >= 30) || (hours > 13 && hours < 19) || (hours === 19 && minutes < 30)) return 'afternoon';
        return 'evening';
    };

    const getAdjustedDate = (date: Date = new Date()): Date => {
        if (isNaN(date.getTime())) {  // Verifica se la data è valida
            console.error('Invalid date passed to getAdjustedDate:', date);
            return new Date();  // Oppure lancia un errore o gestiscilo diversamente
        }

        const hours = new Date().getHours();  // Ora corrente del server
        const minutes = new Date().getMinutes();

        console.log(`Current time: ${hours}:${minutes}, Original date: ${date.toISOString()}`);

        // Applica la logica solo se la data è oggi e l'ora è prima delle 6 AM
        if (isToday(date) && (hours < 6 || (hours === 5 && minutes === 59))) {
            const adjustedDate = subDays(date, 1);
            console.log(`Adjusted date (subtracted a day): ${adjustedDate.toISOString()}`);
            return adjustedDate;
        }

        console.log(`No adjustment needed, returning original date: ${date.toISOString()}`);
        return date;
    };


    const fetchAvailabilities = useCallback(async (date: Date, retryCount: number = 0) => {
        if (!token || !user?.id) {
            console.log('Token or user ID not available, retrying...');
            if (retryCount < MAX_RETRIES) {
                setTimeout(() => fetchAvailabilities(date, retryCount + 1), RETRY_DELAY);
            } else {
                setLoading(false);
                setErrorMessage('Failed to load availabilities. Please try again later.');
            }
            return;
        }

        setLoading(true);
        setErrorMessage(null);

        try {
            const startDate = format(startOfMonth(date), 'yyyy-MM-dd');
            const endDate = format(endOfMonth(date), 'yyyy-MM-dd');
            console.log(`Fetching availabilities from ${startDate} to ${endDate}`);
            const response = await axios.get<AvailabilityData[]>(`${API_URL}/availability`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { startDate, endDate, userId: user.id }
            });
            console.log('Fetched availabilities:', response.data);
            setAvailabilities(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching availabilities:', error);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    setErrorMessage(`Error ${error.response.status}: ${error.response.data.message || 'Unknown error'}`);
                } else if (error.request) {
                    setErrorMessage('No response received from server. Please check your connection.');
                } else {
                    setErrorMessage('An error occurred while setting up the request.');
                }
            } else {
                setErrorMessage('An unknown error occurred.');
            }
            setLoading(false);
        }
    }, [token, user]);

    useEffect(() => {
        fetchAvailabilities(selectedMonth);
    }, [selectedMonth, fetchAvailabilities]);

    const handleMonthChange = (direction: 'next' | 'previous') => {
        setSelectedMonth(prevMonth => {
            const newMonth = direction === 'next'
                ? addMonths(prevMonth, 1)
                : addMonths(prevMonth, -1);
            fetchAvailabilities(newMonth);
            return newMonth;
        });
    };


    const fetchDailyAvailabilities = useCallback(async (date: Date, retries = 3) => {
        if (!token || !user?.id) return;
       
        setIsDailyAvailabilitiesLoading(true);
        for (let i = 0; i < retries; i++) {
            try {
                const formattedDate = format(date, 'yyyy-MM-dd');
                const response = await axios.get<AvailabilityData[]>(`${API_URL}/availability/daily`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { date: formattedDate, userId: user.id }
                });
    
                console.log('Daily availabilities fetched:', response.data);
    
                if (response.data.length > 0) {
                    setDailyAvailabilities(response.data.map(item => ({
                        ...item,
                        checkInTime: item.checkInTime || null,
                        checkOutTime: item.checkOutTime || null
                    })));
                    setIsDailyAvailabilitiesLoading(false);
                    return; // Exit the function if we have data
                }
    
                // If we don't have data, wait before trying again
                if (i < retries - 1) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            } catch (error) {
                console.error('Error fetching daily availabilities:', error);
                if (i === retries - 1) {
                    setIsDailyAvailabilitiesLoading(false);
                    throw error; // Throw error on last retry
                }
            }
        }
    
        // If we've exhausted all retries and still don't have data, set an empty array
        setDailyAvailabilities([]);
        setIsDailyAvailabilitiesLoading(false);
    }, [token, user]);


    useEffect(() => {
        if (selectedDate) {
            console.log("SELECTED DATE: ", selectedDate)
            fetchDailyAvailabilities(selectedDate);
        }
    }, [selectedDate, fetchDailyAvailabilities]);

    useEffect(() => {
        const loadInitialCheckInStatus = async () => {
            if (!token || !user) return;
            try {
                const adjustedDate = getAdjustedDate();
                const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
                const response = await axios.get<AvailabilityData[]>(`${API_URL}/availability/daily`, {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { date: formattedDate, userId: user.id }
                });
                const currentPeriod = getCurrentPeriod();
                const todayAvailability = response.data.find(a => a.period === currentPeriod);
                if (todayAvailability?.checkOutTime) {
                    setCheckInStatus('checkedOut');
                } else if (todayAvailability?.checkInTime) {
                    setCheckInStatus('checkedIn');
                } else {
                    setCheckInStatus('none');
                }
            } catch (error) {
                console.error('Error loading initial check-in status:', error);
                setCheckInStatus('none');
            }
        };

        loadInitialCheckInStatus();
    }, [token, user]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!selectedDate || !token || !user) return;
    
        try {
            const response = await axios.post<AvailabilityData>(`${API_URL}/availability`, {
                date: format(selectedDate, 'yyyy-MM-dd'),
                period,
                status,
                note,
                userId: user.id
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            setAvailabilities(prev => {
                const updatedAvailabilities = prev.filter(a =>
                    !(a.date === format(selectedDate, 'yyyy-MM-dd') && a.period === period)
                );
                return [...updatedAvailabilities, response.data];
            });
    
            // Fetch daily availabilities after creating a new one
            await fetchDailyAvailabilities(selectedDate);
    
            setStatus('available');
            setNote('');
        } catch (error) {
            console.error('Error setting availability:', error);
            setErrorMessage('Failed to set availability. Please try again.');
        }
    };

    const handleCheckIn = async () => {
        try {
            const adjustedDate = getAdjustedDate();
    
            const response = await axios.post(`${API_URL}/availability/check-in`, {
                userId: user?.id,
                date: format(adjustedDate, 'yyyy-MM-dd'),
                period: getCurrentPeriod()
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            setCheckInStatus('checkedIn');
    
            setAvailabilities(prev => {
                const updatedAvailabilities = prev.map(a => {
                    if (a.date === format(adjustedDate, 'yyyy-MM-dd') && a.period === getCurrentPeriod()) {
                        const checkInTime = response.data.checkInTime ? response.data.checkInTime : 'not checked in';
                        return { ...a, checkInTime: checkInTime };
                    }
                    return a;
                });
                return updatedAvailabilities;
            });
    
            // Aggiorna la data selezionata alla data corrente
            setSelectedDate(adjustedDate);
            fetchDailyAvailabilities(adjustedDate);
        } catch (error) {
            console.error('Error during check-in:', error);
        }
    };


    const handleCheckOut = async () => {
        try {
            const currentDate = new Date();
            const adjustedDate = getAdjustedDate(currentDate);
            const response = await axios.post(`${API_URL}/availability/check-out`, {
                userId: user?.id,
                date: format(adjustedDate, 'yyyy-MM-dd'),
                period: getCurrentPeriod(),
                checkOutTime: currentDate.toISOString()
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCheckInStatus('checkedOut');
            setAvailabilities(prev => {
                const updatedAvailabilities = prev.map(a =>
                    a.date === format(adjustedDate, 'yyyy-MM-dd') && a.period === getCurrentPeriod()
                        ? { ...a, checkOutTime: response.data.checkOutTime }
                        : a
                );
                return updatedAvailabilities;
            });
            setSelectedDate(adjustedDate);
            fetchDailyAvailabilities(adjustedDate);
        } catch (error) {
            console.error('Error during check-out:', error);
        }
    };

    const handleCancelCheckIn = async () => {
        try {
            const adjustedDate = getAdjustedDate();
            await axios.post(`${API_URL}/availability/cancel-check-in`, {
                userId: user?.id,
                date: format(adjustedDate, 'yyyy-MM-dd'),
                period: getCurrentPeriod()
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCheckInStatus('none');
            setAvailabilities(prev => {
                const updatedAvailabilities = prev.map(a =>
                    a.date === format(adjustedDate, 'yyyy-MM-dd') && a.period === getCurrentPeriod()
                        ? { ...a, checkInTime: undefined, checkOutTime: undefined }
                        : a
                );
                return updatedAvailabilities;
            });
            setSelectedDate(adjustedDate);
            fetchDailyAvailabilities(adjustedDate);
        } catch (error) {
            console.error('Error canceling check-in:', error);
        }
    };

    const handleCancelCheckOut = async () => {
        try {
            const adjustedDate = getAdjustedDate();
            await axios.post(`${API_URL}/availability/cancel-check-out`, {
                userId: user?.id,
                date: format(adjustedDate, 'yyyy-MM-dd'),
                period: getCurrentPeriod()
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCheckInStatus('checkedIn');
            setAvailabilities(prev => {
                const updatedAvailabilities = prev.map(a =>
                    a.date === format(adjustedDate, 'yyyy-MM-dd') && a.period === getCurrentPeriod()
                        ? { ...a, checkOutTime: undefined }
                        : a
                );
                return updatedAvailabilities;
            });
            setSelectedDate(adjustedDate);
            fetchDailyAvailabilities(adjustedDate);
        } catch (error) {
            console.error('Error canceling check-out:', error);
        }
    };

    const handleBulkUnavailability = async () => {
        if (!bulkStartDate || !bulkEndDate || !token || !user) return;

        const hasCheckIn = availabilities.some(availability =>
            isWithinInterval(parseISO(availability.date), { start: bulkStartDate, end: bulkEndDate }) &&
            availability.checkInTime
        );

        if (hasCheckIn) {
            setErrorMessage("Can't set bulk unavailability for periods with existing check-ins.");
            return;
        }

        try {
            await axios.post(`${API_URL}/availability/bulk-unavailable`, {
                startDate: format(bulkStartDate, 'yyyy-MM-dd'),
                endDate: format(bulkEndDate, 'yyyy-MM-dd'),
                userId: user.id,
                note: bulkNote
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            const start = bulkStartDate;
            const end = bulkEndDate;
            const daysInRange = eachDayOfInterval({ start, end });

            setAvailabilities(prev => {
                const updatedAvailabilities = [...prev];
                daysInRange.forEach(day => {
                    ['morning', 'afternoon', 'evening'].forEach(period => {
                        const index = updatedAvailabilities.findIndex(
                            a => a.date === format(day, 'yyyy-MM-dd') && a.period === period
                        );
                        if (index !== -1) {
                            updatedAvailabilities[index] = {
                                ...updatedAvailabilities[index],
                                status: 'unavailable',
                                note: bulkNote
                            };
                        } else {
                            updatedAvailabilities.push({
                                id: Math.random(), // Temporary ID
                                date: format(day, 'yyyy-MM-dd'),
                                period: period as 'morning' | 'afternoon' | 'evening',
                                status: 'unavailable',
                                note: bulkNote,
                                isAutoGenerated: false
                            });
                        }
                    });
                });
                return updatedAvailabilities;
            });

            if (selectedDate && isWithinInterval(selectedDate, { start, end })) {
                fetchDailyAvailabilities(selectedDate);
            }

            setBulkStartDate(null);
            setBulkEndDate(null);
            setBulkNote('');
        } catch (error) {
            console.error('Error setting bulk unavailability:', error);
            setErrorMessage('Failed to set bulk unavailability. Please try again.');
        }
    };

    const renderCheckInOutButton = () => {
        const adjustedDate = getAdjustedDate();
        const currentPeriod = getCurrentPeriod();

        if (checkInStatus === 'loading') {
            return <CircularProgress size={24} />;
        }

        return (
            <>
                <Typography variant="subtitle1" gutterBottom>
                    {`Date: ${format(adjustedDate, 'yyyy-MM-dd')} | Period: ${currentPeriod}`}
                </Typography>
                {checkInStatus === 'none' && (
                    <Button
                        onClick={handleCheckIn}
                        variant="contained"
                        color="success"
                        fullWidth
                    >
                        Check-In
                    </Button>
                )}
                {checkInStatus === 'checkedIn' && (
                    <>
                        <Button
                            onClick={handleCheckOut}
                            variant="contained"
                            color="error"
                            fullWidth
                            sx={{ mb: 1 }}
                        >
                            Check-Out
                        </Button>
                        <Button
                            onClick={handleCancelCheckIn}
                            variant="outlined"
                            color="warning"
                            fullWidth
                        >
                            Cancel Check-In
                        </Button>
                    </>
                )}
                {checkInStatus === 'checkedOut' && (
                    <>
                        <Button
                            onClick={handleCancelCheckOut}
                            variant="outlined"
                            color="warning"
                            fullWidth
                            sx={{ mb: 1 }}
                        >
                            Cancel Check-Out
                        </Button>
                        <Button
                            onClick={handleCancelCheckIn}
                            variant="outlined"
                            color="warning"
                            fullWidth
                            disabled
                        >
                            Cancel Check-In
                        </Button>
                    </>
                )}
            </>
        );
    };

    const getColorForStatus = (availability: AvailabilityData | undefined, isSunday: boolean) => {
        if (isSunday) return '#ffffff';
        if (!availability) return '#e0e0e0';
        if (availability?.checkInTime) return '#4caf50'; // Green for checked-in
        switch (availability?.status) {
            case 'available': return '#2196f3';
            case 'unavailable': return '#f44336';
            case 'uncertain': return '#ffeb3b';
            default: return '#e0e0e0';
        }
    };

    const renderCalendar = () => {
        const days = eachDayOfInterval({
            start: startOfMonth(selectedMonth),
            end: endOfMonth(selectedMonth)
        });
    
        const today = new Date();
    
        return days.map((day, index) => {
            const dayAvailabilities = availabilities.filter(a => a.date === format(day, 'yyyy-MM-dd'));
            const isDaySunday = isSunday(day);
            const isToday = isSameDay(day, today);
    
            return (
                <Grid item key={index} xs={1}>
                    <Box sx={{ 
                        textAlign: 'center', 
                        mb: 0.5,
                        backgroundColor: isToday ? '#e3f2fd' : 'transparent', // Light blue background for today
                        borderRadius: '4px',
                        padding: '2px'
                    }}>
                        <Typography 
                            variant="caption" 
                            display="block"
                            sx={{
                                color: isDaySunday ? 'red' : 'inherit',
                                fontWeight: isDaySunday ? 'bold' : 'normal'
                            }}
                        >
                            {format(day, 'EEE')}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                color: isDaySunday ? 'red' : (isToday ? '#1976d2' : 'inherit'), // Blue color for today's date
                                fontWeight: isDaySunday || isToday ? 'bold' : 'normal'
                            }}
                        >
                            {format(day, 'd')}
                        </Typography>
                    </Box>
                    {['morning', 'afternoon', 'evening'].map((period) => {
                        const availability = dayAvailabilities.find(a => a.period === period);
                        return (
                            <Paper
                                key={`${index}-${period}`}
                                elevation={1}
                                sx={{
                                    height: 20,
                                    backgroundColor: getColorForStatus(availability, isDaySunday),
                                    cursor: isDaySunday ? 'default' : 'pointer',
                                    '&:hover': { opacity: isDaySunday ? 1 : 0.8 },
                                    position: 'relative',
                                    border: isToday ? '1px solid #1976d2' : 'none', // Blue border for today's availability slots
                                }}
                                onClick={() => {
                                    if (!isDaySunday) {
                                        setSelectedDate(day);
                                        setPeriod(period as 'morning' | 'afternoon' | 'evening');
                                        setStatus(availability?.status || 'available');
                                        setNote(availability?.note || '');
                                    }
                                }}
                            >
                                {availability?.checkOutTime && (
                                    <LockIcon
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            fontSize: 14,
                                            color: 'red'
                                        }}
                                    />
                                )}
                            </Paper>
                        );
                    })}
                </Grid>
            );
        });
    };

    const paperStyle = {
        p: 3,
        height: '100%',
        boxShadow: 3,
        border: '1px solid #e0e0e0',
        borderRadius: 2,
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 3,
                    background: 'linear-gradient(45deg, #00BFFF 30%, #1E90FF 90%)',
                    borderRadius: 2,
                    boxShadow: '0 3px 5px 2px rgba(0, 191, 255, .3)',
                    color: 'white',
                    padding: 2,
                }}>
                    <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                        Availability Management
                    </Typography>
                    <Typography variant="subtitle2" align="center">
                        Manage your availability efficiently
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    {/* Calendar View */}
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ ...paperStyle, display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Button onClick={() => handleMonthChange('previous')} disabled={loading}>
                                    Previous Month
                                </Button>
                                <Typography variant="h6">
                                    {format(selectedMonth, 'MMMM yyyy')}
                                </Typography>
                                <Button onClick={() => handleMonthChange('next')} disabled={loading}>
                                    Next Month
                                </Button>
                            </Box>
                            {loading && <CircularProgress />}
                            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                            {loading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <CircularProgress />
                                </Box>
                            ) : (
                                <Grid container spacing={1}>
                                    {renderCalendar()}
                                </Grid>
                            )}
                        </Paper>
                    </Grid>
                    {/* Availability Form and Check-In/Out */}
                    <Grid item xs={12} md={4}>
                        <Paper sx={paperStyle}>
                            <form onSubmit={handleSubmit}>
                                <DatePicker
                                    label="Date"
                                    value={selectedDate}
                                    onChange={(newValue) => setSelectedDate(newValue)}
                                    sx={{ mb: 2, width: '100%' }}
                                />
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel sx={{ backgroundColor: 'white', px: 1 }}>Period</InputLabel>
                                    <Select
                                        value={period}
                                        onChange={(e) => setPeriod(e.target.value as 'morning' | 'afternoon' | 'evening')}
                                    >
                                        <MenuItem value="morning">Morning</MenuItem>
                                        <MenuItem value="afternoon">Afternoon</MenuItem>
                                        <MenuItem value="evening">Evening</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel sx={{ backgroundColor: 'white', px: 1 }}>Status</InputLabel>
                                    <Select
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value as 'available' | 'unavailable' | 'uncertain')}
                                    >
                                        <MenuItem value="available">Available</MenuItem>
                                        <MenuItem value="unavailable">Unavailable</MenuItem>
                                        <MenuItem value="uncertain">Uncertain</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    label="Note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                    sx={{ mb: 2 }}
                                />
                                <Button type="submit" variant="contained" sx={{ width: '100%', mb: 2 }}>
                                    Set Availability
                                </Button>
                            </form>

                            <Box sx={{ mt: 2 }}>
                                <Typography variant="h6" gutterBottom>
                                    Check-In/Out Status
                                </Typography>
                                {renderCheckInOutButton()}
                            </Box>
                        </Paper>
                    </Grid>

                    {/* Daily Availabilities */}
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ ...paperStyle, height: 300, overflow: 'auto' }}>
                            <Typography variant="h6" gutterBottom>
                                Daily Availabilities from Database
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {selectedDate
                                    ? format(getAdjustedDate(selectedDate), 'MMMM d, yyyy')
                                    : 'Select a date'}
                            </Typography>
                            {isDailyAvailabilitiesLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                                    <CircularProgress />
                                </Box>
                            ) : dailyAvailabilities.length === 0 ? (
                                <Typography>No availabilities found for this date.</Typography>
                            ) : (
                            <List>
                                {dailyAvailabilities.map((availability, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`${availability.period}: ${availability.status}`}
                                            secondary={`Note: ${availability.note || 'None'} | Auto-generated: ${availability.isAutoGenerated ? 'Yes' : 'No'}
                                    ${availability.checkInTime ? `| Check-in: ${format(parseISO(availability.checkInTime), 'HH:mm:ss')}` : ''}
                                    ${availability.checkOutTime ? `| Check-out: ${format(parseISO(availability.checkOutTime), 'HH:mm:ss')}` : ''}`}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                            )}
                        </Paper>
                    </Grid>

                    {/* Bulk Unavailability */}
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ ...paperStyle, height: 300, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" gutterBottom>
                                Set Bulk Unavailability
                            </Typography>
                            <DatePicker
                                label="Start Date"
                                value={bulkStartDate}
                                onChange={(newValue) => setBulkStartDate(newValue)}
                                sx={{ mb: 2, width: '100%' }}
                            />
                            <DatePicker
                                label="End Date"
                                value={bulkEndDate}
                                onChange={(newValue) => setBulkEndDate(newValue)}
                                sx={{ mb: 2, width: '100%' }}
                            />
                            <TextField
                                fullWidth
                                label="Note"
                                value={bulkNote}
                                onChange={(e) => setBulkNote(e.target.value)}
                                sx={{ mb: 2, flexGrow: 1 }}
                                multiline
                            />
                            <Button
                                onClick={handleBulkUnavailability}
                                variant="contained"
                                sx={{ width: '100%' }}
                            >
                                Set Bulk Unavailability
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
                <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
                    <MuiAlert elevation={6} variant="filled" severity="error" onClose={() => setErrorMessage(null)}>
                        {errorMessage}
                    </MuiAlert>
                </Snackbar>
            </Container>
        </LocalizationProvider>
    );
};

export default Availability;