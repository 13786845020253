import React from 'react';
import { Grid, Box, Typography, Paper, Tooltip } from '@mui/material';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSunday, isToday } from 'date-fns';
import { AvailabilityData } from '../types';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@mui/material/styles';

interface AvailabilityCalendarProps {
    availabilities: AvailabilityData[];
    username?: string;
    isOverview?: boolean;
}


const LockIconWrapper = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
});

const DayBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isCurrentDay' && prop !== 'isDaySunday',
})<{ isCurrentDay: boolean; isDaySunday: boolean }>(({ theme, isCurrentDay, isDaySunday }) => ({
    textAlign: 'center',
    mb: 0.5,
    backgroundColor: isCurrentDay ? theme.palette.primary.light : 'transparent',
    borderRadius: '4px',
    padding: isCurrentDay ? '4px' : '2px',
    border: isCurrentDay ? `2px solid ${theme.palette.primary.main}` : 'none',
    boxShadow: isCurrentDay ? `0 0 10px ${theme.palette.primary.main}` : 'none',
    transform: isCurrentDay ? 'scale(1.1)' : 'scale(1)',
    zIndex: isCurrentDay ? 1 : 'auto',
    transition: theme.transitions.create(['transform', 'box-shadow'], {
        duration: theme.transitions.duration.short,
    }),
}));

const DayTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'isCurrentDay' && prop !== 'isDaySunday',
})<{ isCurrentDay: boolean; isDaySunday: boolean }>(({ theme, isCurrentDay, isDaySunday }) => ({
    color: isCurrentDay ? theme.palette.primary.contrastText : 
           (isDaySunday ? theme.palette.error.main : theme.palette.text.primary),
    fontWeight: isCurrentDay || isDaySunday ? 'bold' : 'normal',
}));

const AvailabilityCalendarAdmin: React.FC<AvailabilityCalendarProps> = ({ availabilities, username, isOverview }) => {
    const theme = useTheme();

    const renderCalendar = () => {
        const currentDate = new Date();
        const days = eachDayOfInterval({
            start: startOfMonth(currentDate),
            end: endOfMonth(currentDate)
        });

        return days.map((day, index) => {
            const dayAvailabilities = availabilities.filter(a => a.date === format(day, 'yyyy-MM-dd'));
            const isDaySunday = isSunday(day);
            const isCurrentDay = isToday(day);

            return (
                <Grid item key={index} xs={1}>
                    <DayBox isCurrentDay={isCurrentDay} isDaySunday={isDaySunday}>
                        <DayTypography
                            variant="caption"
                            display="block"
                            isCurrentDay={isCurrentDay}
                            isDaySunday={isDaySunday}
                        >
                            {format(day, 'EEE')}
                        </DayTypography>
                        <DayTypography
                            variant="subtitle2"
                            isCurrentDay={isCurrentDay}
                            isDaySunday={isDaySunday}
                        >
                            {format(day, 'd')}
                        </DayTypography>
                    </DayBox>
                    {['morning', 'afternoon', 'evening'].map((period) => {
                        const availability = dayAvailabilities.find(a => a.period === period);
                        return (
                            <Tooltip
                                key={`${index}-${period}`}
                                title={
                                    <>
                                        <div>{`${period}: ${availability ? availability.status : 'N/A'}`}</div>
                                        {availability?.note && <div>{`Note: ${availability.note}`}</div>}
                                        {availability?.checkInTime && <div>{`Check-in: ${format(new Date(availability.checkInTime), 'HH:mm:ss')}`}</div>}
                                        {availability?.checkOutTime && <div>{`Check-out: ${format(new Date(availability.checkOutTime), 'HH:mm:ss')}`}</div>}
                                    </>
                                }
                            >
                                <Paper
                                    elevation={isCurrentDay ? 3 : 1}
                                    sx={{
                                        height: isCurrentDay ? 24 : 20,
                                        backgroundColor: getColorForStatus(availability, isDaySunday),
                                        position: 'relative',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        transform: isCurrentDay ? 'scale(1.1)' : 'scale(1)',
                                        zIndex: isCurrentDay ? 1 : 'auto',
                                        transition: theme.transitions.create(['transform', 'height'], {
                                            duration: theme.transitions.duration.short,
                                        }),
                                    }}
                                >
                                    {availability?.checkOutTime && (
                                        <LockIconWrapper>
                                            <LockIcon fontSize="small" />
                                        </LockIconWrapper>
                                    )}
                                </Paper>
                            </Tooltip>
                        );
                    })}
                </Grid>
            );
        });
    };

    const getColorForStatus = (availability: AvailabilityData | undefined, isSunday: boolean) => {
        if (isSunday) return '#ffffff';
        if (!availability) return '#e0e0e0';
        if (availability.checkInTime) return '#4caf50'; // Green for checked-in
        switch (availability.status) {
            case 'available': return '#2196f3';
            case 'unavailable': return '#f44336';
            case 'uncertain': return '#ffeb3b';
            default: return '#e0e0e0';
        }
    };

    return (
        <Box>
            {username && (
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        color: theme => theme.palette.primary.main,
                        mb: 2
                    }}
                >
                    {username}'s Availability
                </Typography>
            )}
            <Grid container spacing={1}>
                {renderCalendar()}
            </Grid>
        </Box>
    );
};

export default AvailabilityCalendarAdmin;