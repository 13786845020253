import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Navbar: React.FC = () => {
    const { user, logout } = useAuth();

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Presence App
                </Typography>
                {user && (
                    <Box>
                        <Button color="inherit" component={RouterLink} to="/dashboard">Dashboard</Button>
                        <Button color="inherit" component={RouterLink} to="/availability">Availability</Button>
                        {user.isSupervisor && (
                            <Button color="inherit" component={RouterLink} to="/admin">Admin View</Button>
                        )}
                        <Button color="inherit" onClick={logout}>Logout</Button>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;