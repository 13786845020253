import axios from 'axios';
import { User, AvailabilityData } from '../types';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

export const fetchUsers = async (token: string): Promise<User[]> => {
    if (!token) throw new Error("No authentication token provided");
    const response = await axios.get(`${API_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
};

export const fetchUserAvailabilities = async (token: string, userId: number): Promise<AvailabilityData[]> => {
    if (!token) throw new Error("No authentication token provided");
    const response = await axios.get(`${API_URL}/availability/user/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
    });

    // Fetch the user details to get the username
    const userResponse = await axios.get(`${API_URL}/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
    });

    // Add the username to each availability
    return response.data.map((availability: AvailabilityData) => ({
        ...availability,
        username: userResponse.data.username
    }));
};

export const updateUserRole = async (token: string, userId: number, isSupervisor: boolean): Promise<User> => {
    const response = await axios.put(`${API_URL}/users/${userId}`, { isSupervisor }, {
        headers: { Authorization: `Bearer ${token}` }
    });
    return response.data;
};
