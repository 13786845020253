import React, { useState } from 'react';
import {
    Typography, Paper, Button, Box, Checkbox, FormControlLabel, Snackbar
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { User } from '../types';
import { useAuth } from '../contexts/AuthContext';

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
}));

const UserListPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: '400px',
    overflowY: 'auto',
    marginTop: theme.spacing(2),
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ReportDownloadViewProps {
    users: User[];
    onBack: () => void;
}

const ReportDownloadView: React.FC<ReportDownloadViewProps> = ({ users, onBack }) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const { token } = useAuth();

    const handleUserSelect = (userId: number) => {
        setSelectedUsers(prev =>
            prev.includes(userId)
                ? prev.filter(id => id !== userId)
                : [...prev, userId]
        );
    };

    const handleSelectAll = () => {
        setSelectedUsers(users.map(user => user.id));
    };

    const handleDeselectAll = () => {
        setSelectedUsers([]);
    };

    const handleDownloadReport = async () => {
        if (!startDate || !endDate) {
            setSnackbarMessage('Please select start and end dates');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        if (selectedUsers.length === 0) {
            setSnackbarMessage('Please select at least one user');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/reports/admin/download`, {
                params: {
                    startDate: startDate.toISOString().split('T')[0],
                    endDate: endDate.toISOString().split('T')[0],
                    userIds: selectedUsers.join(',')
                },
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'report.csv';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
                if (filenameMatch && filenameMatch[1]) {
                    filename = filenameMatch[1];
                }
            }
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

            setSnackbarMessage('Report downloaded successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error downloading report:', error);
            setSnackbarMessage('Error downloading report. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <StyledPaper>
            <Typography variant="h5" gutterBottom>Download Report</Typography>
            <Box display="flex" justifyContent="space-between">
                <UserListPaper elevation={3} style={{ width: '48%' }}>
                    <Typography variant="h6" gutterBottom>Select Users</Typography>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Button variant="outlined" size="small" onClick={handleSelectAll}>Select All</Button>
                        <Button variant="outlined" size="small" onClick={handleDeselectAll}>Deselect All</Button>
                    </Box>
                    {users.map(user => (
                        <FormControlLabel
                            key={user.id}
                            control={
                                <Checkbox
                                    checked={selectedUsers.includes(user.id)}
                                    onChange={() => handleUserSelect(user.id)}
                                    color="primary"
                                />
                            }
                            label={user.username}
                        />
                    ))}
                </UserListPaper>
                <Box width="48%">
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(newValue: Date | null) => setStartDate(newValue)}
                        slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(newValue: Date | null) => setEndDate(newValue)}
                        slotProps={{ textField: { fullWidth: true, margin: 'normal' } }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleDownloadReport}
                        style={{ marginTop: '16px' }}
                    >
                        Download Report
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={onBack}
                        style={{ marginTop: '8px' }}
                    >
                        Back to Dashboard
                    </Button>
                </Box>
            </Box>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </StyledPaper>
    );
};

export default ReportDownloadView;