import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import ReportDownload from '../components/ReportDownload';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

interface AttendanceSummary {
    totalDays: number;
    daysPresent: number;
    totalHours: number;
    averageHours: number;
}

interface WeeklyHours {
    week: string;
    hours: number;
}

const Dashboard: React.FC = () => {
    const { user, token } = useAuth();
    const [attendanceSummary, setAttendanceSummary] = useState<AttendanceSummary | null>(null);
    const [weeklyHours, setWeeklyHours] = useState<WeeklyHours[]>([]);

    useEffect(() => {
        fetchAttendanceSummary();
    }, []);

    const fetchAttendanceSummary = async () => {
        try {
            const response = await axios.get<{ summary: AttendanceSummary, weeklyHours: WeeklyHours[] }>(
                `${process.env.REACT_APP_API_URL}/reports/summary`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { userId: user?.id }
                }
            );
            console.log('Response data:', response.data);  // Log della risposta completa
            setAttendanceSummary(response.data.summary);
            setWeeklyHours(response.data.weeklyHours);
            console.log('Weekly hours set:', response.data.weeklyHours);  // Log dei dati setWeeklyHours
        } catch (error) {
            console.error('Error fetching attendance summary:', error);
        }
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom>
                Welcome, {user?.username}!
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 450 }}>
                        <Typography variant="h6" gutterBottom>
                            Monthly Attendance Summary
                        </Typography>
                        {attendanceSummary && (
                            <Box>
                                <Typography>Total Days in Month: {attendanceSummary.totalDays}</Typography>
                                <Typography>Days Present: {attendanceSummary.daysPresent}</Typography>
                                <Typography>Total Hours Worked: {attendanceSummary.totalHours}</Typography>
                                <Typography>Average Daily Hours: {attendanceSummary.averageHours}</Typography>
                            </Box>
                        )}
                        <Box sx={{ height: 350, mt: 2 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={weeklyHours}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="week"
                                        tick={{fontSize: 12}}
                                        interval={0}
                                    />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="hours" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 450 }}>
                        <Typography variant="h6" gutterBottom>
                            Download Report
                        </Typography>
                        <ReportDownload />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Dashboard;