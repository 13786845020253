// File: src/components/OverviewCalendar.tsx

import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Tooltip, Button, Box } from '@mui/material';
import { startOfWeek, endOfWeek, eachDayOfInterval, format, isSameDay, isSunday, addWeeks, subWeeks, isToday } from 'date-fns';
import { AvailabilityData } from '../types';
import { styled } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@mui/material/styles';

interface OverviewCalendarProps {
    availabilities: AvailabilityData[];
    users: { id: number; username: string }[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: '12px 4px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2,
}));

const DayCell = styled(TableCell)<{ isSunday?: boolean }>(({ theme, isSunday }) => ({
    padding: '8px 4px',
    color: isSunday ? theme.palette.error.main : 'inherit',
    fontWeight: isSunday ? 'bold' : 'normal',
    borderRight: `1px solid ${theme.palette.divider}`,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
}));

const UserCell = styled(TableCell)(({ theme }) => ({
    padding: '12px 8px',
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
    borderRight: `2px solid ${theme.palette.divider}`,
}));

const AvailabilitySquare = styled('div')({
    width: '12px',
    height: '12px',
    display: 'inline-block',
    margin: '0 2px',
    border: '1px solid #ccc',
    position: 'relative',
});

const LockIconWrapper = styled('div')({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
});

const AvailabilityContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
});

const DayAvailabilityCell = styled(TableCell)(({ theme }) => ({
    padding: '8px 4px',
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '50px',
}));

const OverviewCalendar: React.FC<OverviewCalendarProps> = ({ availabilities, users }) => {
    const theme = useTheme();

    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        // Start with the current date when the component mounts
        setCurrentDate(new Date());
    }, []);

    const startDate = startOfWeek(currentDate, { weekStartsOn: 1 }); // Start from Monday
    const endDate = endOfWeek(currentDate, { weekStartsOn: 1 });

    const days = eachDayOfInterval({ start: startDate, end: endDate });

    const handlePreviousWeek = () => {
        setCurrentDate(prevDate => subWeeks(prevDate, 1));
    };

    const handleNextWeek = () => {
        setCurrentDate(prevDate => addWeeks(prevDate, 1));
    };

    const getColorForStatus = (availability: AvailabilityData | undefined) => {
        if (!availability) return '#e0e0e0';
        if (availability.checkInTime) return '#4caf50'; // Green for checked-in
        switch (availability.status) {
            case 'available': return '#2196f3';
            case 'unavailable': return '#f44336';
            case 'uncertain': return '#ffeb3b';
            default: return '#e0e0e0';
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Button onClick={handlePreviousWeek} variant="outlined">Previous Week</Button>
                <Typography variant="h6">
                    {format(startDate, 'MMMM d, yyyy')} - {format(endDate, 'MMMM d, yyyy')}
                </Typography>
                <Button onClick={handleNextWeek} variant="outlined">Next Week</Button>
            </Box>
            <TableContainer component={Paper} sx={{ maxHeight: 500, overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>User</StyledTableCell>
                            {days.map(day => {
                                const daySunday = isSunday(day);
                                const isCurrentDay = isToday(day);
                                return (
                                    <DayCell
                                        key={day.toISOString()}
                                        align="center"
                                        isSunday={daySunday}
                                        sx={{
                                            backgroundColor: isCurrentDay ? theme.palette.action.hover : theme.palette.background.paper,
                                            color: isCurrentDay ? theme.palette.primary.main : (daySunday ? theme.palette.error.main : 'inherit'),
                                            fontWeight: isCurrentDay || daySunday ? 'bold' : 'normal'
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="inherit"
                                            sx={{ fontWeight: 'inherit' }}
                                        >
                                            {format(day, 'dd')}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            display="block"
                                            color="inherit"
                                            sx={{ fontWeight: 'inherit' }}
                                        >
                                            {format(day, 'EEE')}
                                        </Typography>
                                    </DayCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => (
                            <TableRow key={user.id}>
                                <UserCell>{user.username}</UserCell>
                                {days.map(day => (
                                    <DayAvailabilityCell key={day.toISOString()} align="center">
                                        <AvailabilityContainer>
                                            {['morning', 'afternoon', 'evening'].map(period => {
                                                const availability = availabilities.find(a =>
                                                    a.userId === user.id &&
                                                    isSameDay(new Date(a.date), day) &&
                                                    a.period === period
                                                );
                                                return (
                                                    <Tooltip
                                                        key={period}
                                                        title={
                                                            <>
                                                                <div>{`${period}: ${availability ? availability.status : 'N/A'}`}</div>
                                                                {availability?.note && <div>{`Note: ${availability.note}`}</div>}
                                                                {availability?.checkInTime && <div>{`Check-in: ${format(new Date(availability.checkInTime), 'HH:mm:ss')}`}</div>}
                                                                {availability?.checkOutTime && <div>{`Check-out: ${format(new Date(availability.checkOutTime), 'HH:mm:ss')}`}</div>}
                                                            </>
                                                        }
                                                    >
                                                        <AvailabilitySquare
                                                            style={{
                                                                backgroundColor: getColorForStatus(availability)
                                                            }}
                                                        >
                                                            {availability?.checkOutTime && (
                                                                <LockIconWrapper>
                                                                    <LockIcon style={{ fontSize: '10px', color: 'black' }} />
                                                                </LockIconWrapper>
                                                            )}
                                                        </AvailabilitySquare>
                                                    </Tooltip>
                                                );
                                            })}
                                        </AvailabilityContainer>
                                    </DayAvailabilityCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default OverviewCalendar;